html {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
}

#root {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    height: 100vh;
    overflow-y: auto;
    padding-right: 0 !important;
    scroll-behavior: smooth;
}

header {
    padding-right: 0 !important;
}

a {
    text-decoration: none;
    color: inherit;
}

p {
    margin: 0px;
    padding: 0px;
}

/*input:-webkit-autofill,*/
/*input:-webkit-autofill:hover,*/
/*input:-webkit-autofill:focus,*/
/*textarea:-webkit-autofill,*/
/*textarea:-webkit-autofill:hover,*/
/*textarea:-webkit-autofill:focus,*/
/*select:-webkit-autofill,*/
/*select:-webkit-autofill:hover,*/
/*select:-webkit-autofill:focus,*/
/*input:-webkit-autofill::first-line {*/
/*    font-size: 15px;*/
/*    font-weight: 300;*/
/*    -webkit-box-shadow: none !important;*/
/*    box-shadow: none !important;*/
/*    -webkit-text-fill-color: inherit !important;*/
/*    transition: background-color 5000s ease-in-out 0s;*/
/*}*/

.custom-scroll::-webkit-scrollbar {
    width: 8px;
    height: 16px;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background: transparent;
    /*border-radius: 999px;*/
}


.custom-scroll::-webkit-scrollbar-track:vertical {
    border-right: 8px solid transparent;
}

.custom-scroll::-webkit-scrollbar-thumb:vertical {
    border-right: 4px solid #DADADA;
}

.custom-scroll::-webkit-scrollbar-track:horizontal {
    border-bottom: 8px solid transparent;
}

.custom-scroll::-webkit-scrollbar-thumb:horizontal {
    border-bottom: 4px solid #DADADA;
}

.custom-scroll::-webkit-scrollbar-corner,
.custom-scroll::-webkit-resizer {
    background: inherit;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
}

.rotate {
    animation: rotate 0.5s linear forwards;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*Для markdown*/

.g-button__text {
    display: flex;
    height: 100%;
    align-items: center;
}

.md-colorify--gray {
    color: gray
}

.md-colorify--yellow {
    color: yellow;
}

.md-colorify--orange {
    color: orange;
}

.md-colorify--red {
    color: red;
}

.md-colorify--green {
    color: green;
}

.md-colorify--blue {
    color: blue;
}

.md-colorify--purple {
    color: purple;
}

